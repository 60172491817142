<template>
  <div class="flex flex-col text-left">
    <div class="wizard">
      <nav aria-label="Progress">
        <ol
          class="border border-gray-300 rounded divide-y divide-gray-300 md:flex md:divide-y-0"
        >
          <li
            class="relative md:flex-1 md:flex"
            v-for="(step, index) in wizard.steps"
            :key="`step-${index}`"
          >
            <a
              v-if="wizard.currentStep > index + 1"
              href="#"
              class="group flex items-center w-full"
            >
              <span class="p-2 flex items-center text-xs font-medium">
                <span
                  class="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-indigo-600 rounded-full group-hover:bg-indigo-800"
                >
                  <svg
                    class="w-6 h-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <span class="ml-4 text-xs font-medium text-gray-900">{{
                  step.title
                }}</span>
              </span>
            </a>

            <a
              href="#"
              class="p-2 flex items-center text-xs font-medium"
              aria-current="step"
              v-else
            >
              <span
                class="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-indigo-600 rounded-full"
              >
                <span class="text-indigo-600">0{{ index + 1 }}</span>
              </span>
              <span class="ml-4 text-xs font-medium text-indigo-600">{{
                step.title
              }}</span>
            </a>

            <!-- Arrow separator for lg screens and up -->
            <div
              class="hidden md:block absolute top-0 right-0 h-full w-5"
              aria-hidden="true"
            >
              <svg
                class="h-full w-full text-gray-300"
                viewBox="0 0 22 80"
                fill="none"
                preserveAspectRatio="none"
              >
                <path
                  d="M0 -2L20 40L0 82"
                  vector-effect="non-scaling-stroke"
                  stroke="currentcolor"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>
        </ol>
      </nav>

      <div class="pt-8">
        <div v-show="wizard.currentStep == 1" class="flex">
          <div class="w-full">
            <div class="space-y-3 flex flex-col">
              <div class="">
                <label
                  for="name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>
                <input
                  v-model="$v.evaluationTemplate.name.$model"
                  :class="
                    `border rounded px-4 py-2 w-full text-xs focus:outline-none bg-transparent ${$v
                      .evaluationTemplate.name.$error && 'border-red-500'}`
                  "
                  placeholder="Name"
                />
              </div>

              <div class="sm:col-span-3">
                <label
                  for="last_name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Beschreibung
                </label>
                <textarea
                  v-model="$v.evaluationTemplate.description.$model"
                  :class="
                    `border rounded px-4 py-2 w-full text-xs focus:outline-none bg-transparent ${$v
                      .evaluationTemplate.description.$error &&
                      'border-red-500'}`
                  "
                  placeholder="Beschreibung"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col w-full m-auto max-h-96 overflow-y-auto"
          v-show="wizard.currentStep == 2"
        >
          <draggable
            v-model="evaluationTemplate.templateattribute"
            @change="orderChange"
            handle=".handle"
          >
            <transition-group>
              <div
                v-for="(major, index) in evaluationTemplate.templateattribute"
                :key="`major-${index}`"
                class="flex flex-col md:flex-row space-y-3 md:space-y-0 justify-between mb-8 border-b pb-8"
              >
                <div class="flex items-start space-x-2 w-full md:w-1/2">
                  <div class="flex items-center mt-1">
                    <div class="handle cursor-pointer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
                        />
                      </svg>
                    </div>
                    <input
                      type="checkbox"
                      v-model="
                        evaluationTemplate.templateattribute[index].value
                      "
                      @change="updateAttributes(index)"
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="text-sm font-bold">{{ major.name }}</p>
                    <p
                      class="text-xs text-gray-600"
                      v-html="major.description"
                    ></p>
                  </div>
                </div>

                <div
                  class="flex ml-4 md:ml-0 items-start space-x-2 w-full md:w-1/2"
                >
                  <div class="flex items-center mt-1"></div>
                  <ul class="flex flex-col w-full list-none space-y-4">
                    <li
                      v-for="(option, oindex) in major.attributemeta"
                      :key="`minor-${oindex}`"
                      class="text-xs font-medium text-gray-600 border-b w-full"
                    >
                      <input
                        type="checkbox"
                        v-model="
                          evaluationTemplate.templateattribute[index]
                            .attributemeta[oindex].value
                        "
                        @change="updateParent(index, major.attributemeta)"
                      />
                      {{ option.name }}
                    </li>
                  </ul>
                </div>
              </div>
            </transition-group>
          </draggable>
        </div>
      </div>

      <div class="mt-5 sm:mt-6 flex justify-between items-center">
        <a
          href="#"
          @click.prevent="updateCurrent(-1)"
          class="flex justify-center rounded border border-transparent shadow-sm px-2 py-1 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:col-start-2 sm:text-sm"
          v-if="wizard.currentStep > 1"
          >Zurück</a
        >
        <template v-if="wizard.currentStep < wizard.steps.length">
          <span class="w-px"></span>
          <a
            href="#"
            @click.prevent="updateCurrent(1)"
            class="flex justify-center rounded border border-gray-300 shadow-sm px-2 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
            >Weiter</a
          >
        </template>
        <a
          href="#"
          @click.prevent="save"
          class="flex justify-center rounded border border-gray-300 shadow-sm px-2 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
          v-else
          >Speichern</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'
import { required } from 'vuelidate/lib/validators'
import axios from '@/utils/axios'

const tempEvaluationTemplate = {
  name: '',
  description: '',
  templateattribute: [
    {
      name: 'Leistungsbeurteilung',
      description:
        'Bitte wählen Sie die Leistungsbeurteilungen,<br />die Sie in der Vorlage verwernden möchten',
      value: false,
      order: 1,
      attributemeta: [
        {
          name: 'Aufgaben',
          type: 'get',
          value: false,
        },
        {
          name: 'Persönlichkeitskompetenzen',
          type: 'get',
          value: false,
        },
        {
          name: 'Ziele',
          type: 'get',
          value: false,
        },
      ],
    },
    {
      name: 'Ziele',
      description:
        'Sollten Ziele für die nächste Beurteilung definiert werden?',
      type: 'checkbox',
      value: false,
      order: 2,
      attributemeta: [
        {
          name: 'Ziele definieren',
          type: 'get',
          value: false,
        },
      ],
    },
    {
      name: 'Mitarbeiter Zufriedenheit & Feedback',
      description:
        'Sollten Mitarbeiter Zufriedenheit & Feedback für die nächste Beurteilung bewertet werden?',
      type: 'checkbox',
      value: false,
      order: 3,
      attributemeta: [
        {
          name: 'Mitarbeiter Zufriedenheit',
          type: 'state',
          value: false,
        },
        {
          name: 'Feedback',
          type: 'state',
          value: false,
        },
      ],
    },
    {
      name: 'Personalentwicklungsmassnahmen',
      description:
        'Sollten Personalentwicklungsmassnahmen für die nächste Beurteilung bewertet werden?',
      type: 'checkbox',
      value: false,
      order: 4,
      attributemeta: [
        {
          name: 'Personalentwicklungsmassnahmen festlegen',
          type: 'post',
          value: false,
        },
        {
          name: 'Personalentwicklungsmassnahmen beurteilen',
          type: 'post',
          value: false,
        },
      ],
    },
    {
      name: 'Allgemeine Themen',
      description: 'Besprechen von allgemeine Themen',
      type: 'checkbox',
      value: false,
      order: 4,
      attributemeta: [
        {
          name: 'Allgemeine Themen',
          type: 'post',
          value: false,
        },
      ],
    },
  ],
}

export default {
  name: 'new-performance-review-template',
  props: ['payload'],
  components: { draggable },
  data() {
    return {
      evaluationTemplate: {
        ...tempEvaluationTemplate,
      },
      wizard: {
        currentStep: 1,
        steps: [
          {
            id: 1,
            title: 'Beschreibung',
          },
          {
            id: 2,
            title: 'Umfang',
          },
        ],
      },
    }
  },
  validations() {
    return {
      evaluationTemplate: {
        name: {
          required,
        },
        description: {
          required,
        },
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.payload) {
        this.evaluationTemplate = { ...this.payload }
      }
    },
    save() {
      if (this.evaluationTemplate.id) {
        axios
          .patch(
            `performance-evaluation/template/${this.evaluationTemplate.id}/`,
            {
              name: this.evaluationTemplate.name,
              description: this.evaluationTemplate.description,
              templateattribute: this.evaluationTemplate.templateattribute,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(response => {
            if (response.status === 200) {
              // this.$toast.success(response.data.message)
              this.$emit('close-modal', { show: false })
              this.evaluationTemplate = { ...tempEvaluationTemplate }
              this.wizard.currentStep = 1
            } else {
              this.$toast.error(response.detail)
              this.$emit('close-modal', { show: false })
            }
          })
          .catch(err => {
            this.$toast.error(err.response.data.detail)
            this.$emit('close-modal', { show: false })
          })
      } else {
        axios
          .post(
            `performance-evaluation/template/`,
            {
              name: this.evaluationTemplate.name,
              description: this.evaluationTemplate.description,
              templateattribute: this.evaluationTemplate.templateattribute,
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then(response => {
            if (response.status === 201) {
              // this.$toast.success(response.data.message)
              this.$emit('close-modal', { show: false })
              this.evaluationTemplate = { ...tempEvaluationTemplate }
              this.wizard.currentStep = 1
            } else {
              this.$toast.error(response.detail)
              this.$emit('close-modal', { show: false })
            }
          })
          .catch(err => {
            this.$toast.error(err.response.data.detail)
            this.$emit('close-modal', { show: false })
          })
      }
    },
    updateCurrent(step) {
      this.$v.evaluationTemplate.$touch()

      const newValue = {
        currentStep: this.wizard.currentStep + step,
        steps: this.wizard.steps,
      }

      if (!this.$v.$error) this.wizard = newValue
    },
    updateAttributes(index) {
      this.evaluationTemplate.templateattribute[index].attributemeta.forEach(
        element => {
          element.value = this.evaluationTemplate.templateattribute[index].value
        }
      )
    },
    updateParent(index, children) {
      this.evaluationTemplate.templateattribute[index].value = _.some(
        children,
        ['value', true]
      )
    },
    orderChange() {
      this.evaluationTemplate.templateattribute.map(
        (attribute, index) => (attribute.order = index)
      )
    },
  },
}
</script>
